<template>
  <div id="nav">
    <router-link :to="{ name: 'CompanyCreate'}"  style="text-decoration: none; color: inherit;"><div><span class="material-symbols-outlined">Add_Circle</span><span >Create Comapny</span></div></router-link>

  </div>
  <div class="container" id="x">
    <table class="table">
      <thead>
        <tr>
          <td>Company Name</td>
          <td>Company Id</td>
          <td>Details</td>
          <td>Edit</td>
        </tr>
      </thead>
      <tr v-for="company in companies" :key="company.id">
        <td>{{ company.name }}</td>
        <td>{{ company.id }}</td>
        <td width><router-link :key="company.id" :to="{ name: 'CompanyDetails', params: { id: company.id } }" style="text-decoration: none; color: inherit;"> <span class="material-symbols-outlined">info</span></router-link> </td>

        <td><router-link :key="company.id" :to="{ name: 'CompanyEdit', params: { id: company.id } }" style="text-decoration: none; color: inherit;"> <span class="material-symbols-outlined">edit</span></router-link> </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GlobalSettings',
  props: {
    msg: String
  },
  data: () => ({
    // data
    companies: [{
      id: Number
    }]
  }),
  methods: {
    // methods
    async getData() {
      console.log("Getting data")
      const response = await this.axios.get("company/allCompanies");

      this.companies = response.data;

    },

  },
  created() {
    console.log("Created")

    this.getData();
  },
})











</script>