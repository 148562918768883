<template>
    <div class="container">
    <CompanyView @on-submit="CreateCompany" @readOnly=false> </CompanyView>
</div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import  CompanyView  from '@/components/Company/CompanyView.vue'
export default defineComponent({
    components : {CompanyView},
    name: 'CompanyCreate',
    methods: {
        CreateCompany(company: any){
            console.log(company.name);
            this.axios.post(`company`, {
                name: company.name,
                emails: company.emails
            }).then(  (response) => {
                const self = this;
                self.$router.push({ name: 'CompanyDetails', params: { id: response.data.id } })
            }
            )
        }
    }
})
</script>