
<template>
    <div class="container">
        <div class="container">
            <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </p>
        </div>

        <div class="container">
            <AssetTestView @on-submit="CreateAssetTest" :internalAssetId="assetId"> </AssetTestView>
        </div>
    </div>
</template>

<script lang="ts">

    import AssetTestView from "./AssetTest.vue";
    import { defineComponent } from 'vue';
        export default defineComponent({
            components: { AssetTestView  },
            name: 'AssetTestCreate',
            props: {
                assetId: String,
            },
            data() {
                return {
                    errors: [] as String[]
                }
            },
            methods: {
                CreateAssetTest(assetTest: any) {

                    this.errors = [];
                    if (assetTest.testResult < 0) {
                        this.errors.push("Test Result required");
                    }


                    if (this.errors.length > 0) {
                        return;
                    }
                    console.log(assetTest.name);

                    let companyId = this.$cookies.get('companyId');
                    this.axios.post(`company/${companyId}/assettest`, {
                        assetId: this.assetId,
                        note: assetTest.note,
                        testResult: assetTest.testResult,
                        timestamp: assetTest.timestamp
                    }).then(  (response) => {
                        const self = this;
                        let printLabel = 'false' as string
                        if (assetTest.testResult == 0) {
                            printLabel = 'true'
                        }
                        self.$router.push({ name: 'AssetTestDetails', params: { assetTestId: response.data.id, assetId: this.assetId }, query: { printLabel: printLabel } })
                    }
                    )
                }
            }

        })
</script>  