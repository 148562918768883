
<template>
    <div class="container">
        <AssetTestView :assetTest="assetTest" :key="assetTest.id" :internalAssetId="assetId" :readOnly="true"></AssetTestView>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div @click="print" style="cursor: pointer"><span class="material-symbols-outlined">print</span><br />Print</div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
    import { printZpl } from '../../printing';
    import AssetTestView from "./AssetTest.vue";
    import { defineComponent } from 'vue';
        export default defineComponent({
            components: { AssetTestView  },
            name: 'AssetTestDetails',
            props: {
                assetTestId: String,
                assetId: String,
                printLabel: {
                    type: Boolean,
                    default: false
                },
            },
            data() {
                return {
                    assetTest: {} as {
                        id: number,
                        companyId: number,
                        company: string,
                        assetId: number,
                        note: string,
                        testResult: number,
                        testTime: string,
                        nextTestDue: string
                        zpl: string


                    },
                    companyId: this.$cookies.get('companyId'),
                };
            },
            methods: {
                async loadAssetTest() {
                    try {
                        console.log("Loading asset test")
                        console.log(this.assetTestId)
                        let companyId = this.$cookies.get('companyId');
                        console.log("Getting data")
                        const response = await this.axios.get("company/" + companyId + "/assettest/" + this.assetTestId);
                        // JSON responses are automatically parsed.
                        this.assetTest = response.data;
                        console.log("printLabel = " + this.$route.query.printLabel)
                        if (this.$route.query.printLabel) {
                            this.print()
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
                async print() {
                    //const BrowserPrint = require('../../lib/zebra-browser-print-js-v30216/BrowserPrint-3.0.216.min.js')
                    //const Zebra = require('../../lib/zebra-browser-print-js-v30216/BrowserPrint-Zebra-1.0.216.min.js')

                    let url: string = `/company/${this.companyId}/assettest/${this.assetTestId}/print`

                    let zplResp = await this.axios.get(url);
                    let zpl: string = zplResp.data;
                    await this.axios.post(`/company/${this.companyId}/audit`, { action: "Printed asset test", entity: "AssetTestDetails", body: zpl });
                    printZpl(zpl)

                    

                },
            },
            created() {
                this.loadAssetTest();
            }
        })
</script>  