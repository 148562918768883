
<template>
    <AssetView @on-submit="CreateAsset"> </AssetView>
</template>

<script lang="ts">
    
    import { Asset } from "@/types/Asset";
    import AssetView from "./Asset.vue";
    import { defineComponent } from 'vue';
    export default defineComponent({
        components: {AssetView},
        name: 'AssetCreate',
        methods: {
            CreateAsset(asset: Asset){
                console.log(asset.name);
                
                let companyId = this.$cookies.get('companyId');
                this.axios.post(`company/${companyId}/asset`, {
                    name: asset.name,
                    note: asset.note,
                    description: asset.description,
                    assetTypeId: asset.assetTypeId,
                    testedAsCreated: true
                }).then(  (response) => {
                    const self = this;
                    self.$router.push({ name: 'AssetDetails', params: { id: response.data.id }, query: { printLabel: 'true' } })
                }
                )
            }
        }
    })
</script>  