<template>
    <CompanyView :company="company" :readOnly="false" :edit="false" :key="company.id" @on-submit="SaveEdit" />
</template>

<script lang="ts">


import { defineComponent } from 'vue'
import CompanyView from './CompanyView.vue'
export default defineComponent({
    components: {
        CompanyView
    },
    props: {
        id: Number,
    },
    data() {
        return {
            company: {
                id: Number,
                name: String,
                emails: []
            }
        }
    },
    name: 'CompanyCreate',
    methods: {
        async getData() {
            try {
                console.log("Getting data")
                const response = await this.axios.get("company/" + this.id);
                // JSON responses are automatically parsed.
                this.company = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async SaveEdit(){
            console.log(name);
            this.axios.post(`company/${this.company.id}`, {
                name: this.company.name,
                emails: this.company.emails
            }).then(  (response) => {
                const self = this;
                self.$router.push({ name: 'CompanyDetails', params: { id: response.data.id } })
            }
            )
        }
    },
    created() {
        console.log("Created")
        console.log(this.id)
        this.getData();
        console.log(this.company)
    },
})
</script>