<template>
    <div class="container">
        <DataTable :value="assets" :paginator="true" :rows="10" :rowsPerPageOptions="[5,10,20]">
            <Column field="name" header="Asset Name"></Column>
            <Column field="expiry" header="Expiry" sortable></Column>
            <Column header="Status" sortable>
                <template #body="slotProps">
                    <span class="material-symbols-outlined" style="color: green;" v-if="slotProps.data.testResult == 0">done</span>
                    <span class="material-symbols-outlined" style="color: crimson;" v-if="slotProps.data.testResult == 1">close</span>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <router-link :to="{ name: 'AssetDetails', params: { id: slotProps.data.id } }" style="text-decoration: none; color: inherit;">
                        <span class="material-symbols-outlined">info</span>
                    </router-link>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { AssetSummary } from '@/types/AssetSummary';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
    export default defineComponent({
        props: {
            assets: {
                type: Object as PropType<Array<AssetSummary>>,
            },
        },
        name: "AssetList",
        methods: {
        },
        components: {
            DataTable,
            Column, 
        },
        onload() {
            console.log("Loading asset list")
        } ,
    });
</script>