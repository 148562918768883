import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetTestView = _resolveComponent("AssetTestView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_AssetTestView, {
        assetTest: _ctx.assetTest,
        key: _ctx.assetTest.id,
        internalAssetId: _ctx.assetId,
        readOnly: true
      }, null, 8, ["assetTest", "internalAssetId"]))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.print && _ctx.print(...args))),
            style: {"cursor":"pointer"}
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "print", -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode("Print")
          ]))
        ])
      ])
    ])
  ], 64))
}