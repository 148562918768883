<template>

    <div id="nav">
        <router-link :to="{ name: 'AssetCreate'}"  style="text-decoration: none; color: inherit;"><div><span class="material-symbols-outlined">Add_Circle</span><br />Create</div></router-link>
    </div>
    <AssetList :assets="assets" ></AssetList>
</template>


<script lang="ts">
    import { defineComponent } from 'vue';
    import  AssetList  from './AssetList/AssetList.vue' 
    import { AssetSummary } from '@/types/AssetSummary';
    export default defineComponent({
        name: 'OurAssets',
        props: {
        },
        components: { AssetList },
        data() {
            return {
                assets: [] as AssetSummary[],
            };
        },

        methods: {
            async getData() {
                try {
                    console.log("Getting data")
                    
                    let companyId = this.$cookies.get('companyId');
                    const response = await this.axios.get(
                        `company/${companyId}/asset`
                    );
                    // JSON responses are automatically parsed.
                    this.assets = response.data as AssetSummary[];
                } catch (error) {
                    console.log(error);
                }
            },
        },

        created() {
            console.log("Created")

            this.getData();
        }, 
    });

</script>


