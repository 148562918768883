import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-light bg-light" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "",
  id: "navbarSupportedContent ml-auto"
}
const _hoisted_4 = { class: "navbar-nav mr-auto" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  key: 0,
  class: "container-fluid",
  style: {"width":"500px","float":"right"}
}
const _hoisted_11 = { class: "ml-auto" }
const _hoisted_12 = { class: "navbar-nav" }
const _hoisted_13 = { class: "nav-item dropdown right" }
const _hoisted_14 = {
  class: "nav-link dropdown-toggle",
  href: "#",
  id: "navbarDropdown",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_15 = {
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "navbarDropdown"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["href"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["href"]
const _hoisted_21 = { key: 2 }
const _hoisted_22 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "nav-link"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Home")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_6, [
            (_ctx.authenticated)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/assets",
                  class: "nav-link"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Assets")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("li", _hoisted_7, [
            (_ctx.authenticated)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/adhocLabel",
                  class: "nav-link"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Adhoc Label")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("li", _hoisted_8, [
            (_ctx.authenticated)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/joblist",
                  class: "nav-link"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Jobs")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("li", _hoisted_9, [
            (_ctx.authenticated)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/about",
                  class: "nav-link"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("About")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.authenticated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("form", {
              class: "d-flex",
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                class: "form-control me-2",
                type: "search",
                placeholder: "Search",
                "aria-label": "Search",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event))
              }, null, 512), [
                [_vModelText, _ctx.searchString]
              ]),
              _createElementVNode("button", {
                class: "btn btn-outline-success",
                type: "submit",
                onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
              }, "Search", 32)
            ], 32)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("ul", _hoisted_12, [
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.dropdownDisplay), 1),
            _createElementVNode("ul", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myCompanies, (company) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: company.companyId
                }, [
                  _createElementVNode("a", {
                    class: "dropdown-item",
                    href: "#",
                    onClick: ($event: any) => (_ctx.setCompany(company.companyId))
                  }, _toDisplayString(company.companyName), 9, _hoisted_16)
                ]))
              }), 128)),
              _cache[10] || (_cache[10] = _createElementVNode("li", null, [
                _createElementVNode("hr", { class: "dropdown-divider" })
              ], -1)),
              (_ctx.authenticated != true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: _ctx.baseUrl + '/api/auth/externallogin?provider=Google&returnUrl=' + _ctx.baseUrl + '/'
                    }, "Google", 8, _hoisted_18)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.authenticated == true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: _ctx.baseUrl + '/api/auth/ExternalLogOut?returnUrl=' + _ctx.baseUrl
                    }, "Log Out", 8, _hoisted_20)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.authenticated == true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_21, [
                    _createVNode(_component_router_link, {
                      class: "dropdown-item",
                      to: "/companysettings"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Settings")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.authenticated == true && _ctx.globalAdmin == true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_22, [
                    _createVNode(_component_router_link, {
                      class: "dropdown-item",
                      to: "/globalSettings"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Global Settings")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}