import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyView = _resolveComponent("CompanyView")!

  return (_openBlock(), _createBlock(_component_CompanyView, {
    company: _ctx.company,
    readOnly: true,
    edit: true,
    key: _ctx.company.id,
    onEditCompany: _ctx.edit
  }, null, 8, ["company", "onEditCompany"]))
}