
<template>
    <NavBar @search-complete="searchComplete"></NavBar>
    <router-view />
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import NavBar from './components/NavBar.vue';
    import "./app.css"

    export default defineComponent({
        name: 'App',
        components: {
            NavBar,
        },
        watch: {
            'search-complete': function () {
                console.log("Search complete")
                this.searchComplete();
            }
        },
        methods: {
            searchComplete() {
                console.log("Search complete")
            }

        }
    });
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        /*margin-top: 60px;*/
    }
</style>
