import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "col-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetType = _resolveComponent("AssetType")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_component_AssetType, {
      readOnly: true,
      assetType: _ctx.assetType,
      key: _ctx.assetType.id
    }, null, 8, ["assetType"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: { name: 'AssetTypeEdit', params: { id: _ctx.id } },
            style: {"text-decoration":"none","color":"inherit"},
            class: "button"
          }, {
            default: _withCtx(({ navigate }) => [
              _createElementVNode("div", { onClick: navigate }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "edit", -1),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Edit")
              ]), 8, _hoisted_4)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-sm" }, null, -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteAssetType && _ctx.deleteAssetType(...args))),
            class: "button"
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "delete", -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode("Delete")
          ]))
        ])
      ])
    ])
  ], 64))
}