import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AssetList = _resolveComponent("AssetList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: { name: 'AssetCreate'},
        style: {"text-decoration":"none","color":"inherit"}
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "Add_Circle"),
            _createElementVNode("br"),
            _createTextVNode("Create")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_AssetList, { assets: _ctx.assets }, null, 8, ["assets"])
  ], 64))
}