<template>
  <div class="container">
      <form @submit.prevent="$emit('on-submit', form)">
          <div class="mb-3">
              <label for="name" class="form-label">Asset Name</label>
              <input class="form-control" type="text" name="name" v-model="form.name" :disabled="readOnly == 1" required/>
          </div>
          <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <input class="form-control" type="text" name="description" v-model="form.description" :disabled="readOnly == true" />
          </div>
          <div class="mb-3">
              <label for="note" class="form-label">Note</label>
              <input class="form-control" type="text" name="note" v-model="form.note" :disabled="readOnly == true" />
          </div>
          <div class="mb-3" :v-if="readOnly == true">
            <label for="barcode" class="form-label" :v-if="readOnly == true">Barcode</label>
            <input class="form-control" type="text" name="barcode" v-model="form.assetBarcode" :v-if="readOnly == true" :disabled="true" />
          </div>
          <div class="mb-3">
              <label for="assetTypeId" class="form-label">AssetType</label>
              <select class="form-select" aria-label="Default select example" v-model="form.assetTypeId" :disabled="readOnly == true">
                  <option v-for="option in assetTypes" :value="option.id" :key="option.id">
                      {{option.name}} : {{ option.description }}
                  </option>
              </select>
          </div>
          <PVButton class="btn btn-default" type="submit" v-if="readOnly != true">Submit</PVButton>
      </form>
  </div>
</template>

<script>
import { Asset } from '@/types/Asset';
import PVButton from 'primevue/button';

export default {
  props: {
    asset: {
      type: Asset,
    },
    readOnly: {type: Boolean}
  },
  name: "AssetView",
  methods: {
      async getData() { 
          try {
              let companyId = this.$cookies.get('companyId');
              console.log("Getting data")
              const response = await this.axios.get("company/" + companyId + "/assetType" );
              // JSON responses are automatically parsed.
              this.assetTypes = response.data;
          } catch (error) {
              console.log(error);
          }
      },
  },
  components: {
    PVButton
  },
  created() {
      console.log(this.id)
      this.getData();
  },
  data() {
    return {
      form: { 
        name: this.asset?.name || "",
        note: this.asset?.note || "", 
        description: this.asset?.description || "",
        assetBarcode: this.asset?.assetBarcode || "",
        assetTypeId: this.asset?.assetTypeId || -100
      },
      assetTypes: []
    };
  },
};
</script>