import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'JobCreate' },
          style: {"text-decoration":"none","color":"inherit"}
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "material-symbols-outlined" }, "Add_Circle"),
              _createElementVNode("br"),
              _createTextVNode("Create")
            ], -1)
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DataTable, {
        value: _ctx.jobs,
        paginator: true,
        rows: 10,
        rowsPerPageOptions: [5, 10, 20]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: "Job Name"
          }),
          _createVNode(_component_Column, {
            field: "client",
            header: "Client",
            sortable: ""
          }),
          _createVNode(_component_Column, null, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_router_link, {
                to: { name: 'JobDetails', params: { id: slotProps.data.id } },
                style: {"text-decoration":"none","color":"inherit"}
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
                ])),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}