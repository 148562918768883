import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "material-symbols-outlined",
  style: {"color":"green"}
}
const _hoisted_3 = {
  key: 1,
  class: "material-symbols-outlined",
  style: {"color":"crimson"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.assets,
      paginator: true,
      rows: 10,
      rowsPerPageOptions: [5,10,20]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Asset Name"
        }),
        _createVNode(_component_Column, {
          field: "expiry",
          header: "Expiry",
          sortable: ""
        }),
        _createVNode(_component_Column, {
          header: "Status",
          sortable: ""
        }, {
          body: _withCtx((slotProps) => [
            (slotProps.data.testResult == 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "done"))
              : _createCommentVNode("", true),
            (slotProps.data.testResult == 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "close"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_router_link, {
              to: { name: 'AssetDetails', params: { id: slotProps.data.id } },
              style: {"text-decoration":"none","color":"inherit"}
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
              ])),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}