import Vue, { createApp } from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import App from './App.vue'

import router from './router' // <---
//window.BrowserPrint = require('./lib/zebra-browser-print-js-v30216/BrowserPrint-3.0.216.min.js')
//window.Zebra = require('./lib/zebra-browser-print-js-v30216/BrowserPrint-Zebra-1.0.216.min.js')


axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.baseURL = "/api/"
axios.defaults.headers.common['X-Requested-With'] =  'XMLHttpRequest';
//axios.defaults.withCredentials = true

//createApp(App).use(router).use(VueCookies, { expire: '7d'}).use(VueAxios, axios)
createApp(App)
    .use(router)
    .use(VueCookies)
    .use(VueAxios, axios)
    .use(PrimeVue, {
        theme: {
            preset: Aura
        }})
.mount('#app')


