import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "form-group mb-3" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "form-group mb-3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "form-group mb-3" }
const _hoisted_7 = ["onUpdate:modelValue", "disabled"]
const _hoisted_8 = { class: "form-group mb-3" }
const _hoisted_9 = ["onUpdate:modelValue", "disabled"]
const _hoisted_10 = { class: "form-group mb-3" }
const _hoisted_11 = ["onUpdate:modelValue", "disabled"]
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "col-sm" }
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('on-submit', _ctx.form)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { for: "companyName" }, "Company Name", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "companyName",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          placeholder: "Enter company name",
          disabled: _ctx.readOnly == true
        }, null, 8, _hoisted_3), [
          [_vModelText, _ctx.form.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "companyDescription" }, "Phone Number", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "companyDescription",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.phone) = $event)),
          placeholder: "Enter company description",
          disabled: _ctx.readOnly == true
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.form.phone]
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form?.emails, (email) => {
        return (_openBlock(), _createElementBlock("div", {
          key: email.email
        }, [
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { for: "email" }, "Email", -1)),
            _withDirectives((_openBlock(), _createElementBlock("input", {
              type: "text",
              class: "form-control",
              id: "adminEmail",
              "onUpdate:modelValue": ($event: any) => ((email.email) = $event),
              placeholder: "Enter admin email",
              disabled: _ctx.readOnly == true,
              key: email.email
            }, null, 8, _hoisted_7)), [
              [_vModelText, email.email]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives((_openBlock(), _createElementBlock("input", {
              type: "checkbox",
              class: "",
              id: "ownercheckbox",
              "onUpdate:modelValue": ($event: any) => ((email.isOwner) = $event),
              placeholder: "",
              disabled: _ctx.readOnly == true,
              key: email.email
            }, null, 8, _hoisted_9)), [
              [_vModelCheckbox, email.isOwner]
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "ownercheckbox" }, "Owner", -1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives((_openBlock(), _createElementBlock("input", {
              type: "checkbox",
              class: "",
              id: "admincheckbox",
              "onUpdate:modelValue": ($event: any) => ((email.isAdmin) = $event),
              placeholder: "",
              disabled: _ctx.readOnly == true,
              key: email.email
            }, null, 8, _hoisted_11)), [
              [_vModelCheckbox, email.isAdmin]
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "admincheckbox" }, "Admin", -1))
          ])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addEmail && _ctx.addEmail(...args))),
            disabled: _ctx.readOnly == true
          }, "Add Email", 8, _hoisted_14),
          (_ctx.edit==true)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'CompanyEdit', params: { id: _ctx.company.id } },
                style: {"text-decoration":"none","color":"inherit"},
                class: "button"
              }, {
                default: _withCtx(({ navigate }) => [
                  _createElementVNode("div", { onClick: navigate }, _cache[9] || (_cache[9] = [
                    _createElementVNode("span", { class: "material-symbols-outlined" }, "edit", -1),
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode("Edit")
                  ]), 8, _hoisted_15)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary",
            disabled: _ctx.readOnly == true
          }, "Submit", 8, _hoisted_17)
        ])
      ])
    ], 32)
  ]))
}