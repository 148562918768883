import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "nav" }
const _hoisted_2 = {
  class: "container",
  id: "x"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { width: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: { name: 'CompanyCreate'},
        style: {"text-decoration":"none","color":"inherit"}
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "Add_Circle"),
            _createElementVNode("span", null, "Create Comapny")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, "Company Name"),
            _createElementVNode("td", null, "Company Id"),
            _createElementVNode("td", null, "Details"),
            _createElementVNode("td", null, "Edit")
          ])
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: company.id
          }, [
            _createElementVNode("td", null, _toDisplayString(company.name), 1),
            _createElementVNode("td", null, _toDisplayString(company.id), 1),
            _createElementVNode("td", _hoisted_4, [
              (_openBlock(), _createBlock(_component_router_link, {
                key: company.id,
                to: { name: 'CompanyDetails', params: { id: company.id } },
                style: {"text-decoration":"none","color":"inherit"}
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
                ])),
                _: 2
              }, 1032, ["to"]))
            ]),
            _createElementVNode("td", null, [
              (_openBlock(), _createBlock(_component_router_link, {
                key: company.id,
                to: { name: 'CompanyEdit', params: { id: company.id } },
                style: {"text-decoration":"none","color":"inherit"}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "material-symbols-outlined" }, "edit", -1)
                ])),
                _: 2
              }, 1032, ["to"]))
            ])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}