<template>
    <AssetType  :readOnly="false" :assetType="assetType" :key="assetType.id" @on-submit="SaveEdit" > </AssetType>
</template>


<script lang="ts">

    import { defineComponent } from 'vue';
import AssetType from "./AssetType.vue";

    export default defineComponent({
        name: 'AssetTypeDetails',
        components: {  AssetType },
        props: {id: Number},
        data() {
            return {
                assetType: {id: Number}
,
            };
        },

        methods: {
            async getData() { 
                try {
                    console.log("Getting data")
                    
                    let companyId = this.$cookies.get('companyId');
                    const response = await this.axios.get(`company/${companyId}/assetType/${this.id}`);
                    // JSON responses are automatically parsed.
                    this.assetType = response.data;
                } catch (error) {
                    console.log(error);
                }
            }, 
            async SaveEdit(assetTypeDetails: any){
                console.log(assetTypeDetails.name);
                
                let companyId = this.$cookies.get('companyId');
                this.axios.put(`company/${companyId}/assettype/${this.id}`, {
                    name: assetTypeDetails.name,
                    description: assetTypeDetails.description,
                    testInterval: assetTypeDetails.testInterval
                }).then(  (response) => {
                    const self = this;
                    self.$router.push({ name: 'AssetTypeDetails', params: { id: response.data.id } })
                }
                )
            }

        },

        created() {
            console.log(this.id)
            this.getData();
        },
    });

</script>


