export function printZpl(zpl: string) {
    
    // @ts-ignore
    // eslint-disable-next-line
    BrowserPrint.getDefaultDevice("printer",
        function (device: any) {
            console.log(device)


            // eslint-disable-next-line
            device.send(zpl, function (success: any) { console.log("Sent to printer"); }, function (error: any) { console.error("Error:" + error); });

            // @ts-ignore
            // eslint-disable-next-line

        },
        function (error: object) {
            console.log(error)
        });
}