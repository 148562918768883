import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobView = _resolveComponent("JobView")!

  return (_openBlock(), _createBlock(_component_JobView, {
    editable: true,
    job: _ctx.job,
    onOnSubmit: _ctx.EditJob,
    key: _ctx.job.id
  }, null, 8, ["job", "onOnSubmit"]))
}