import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetType = _resolveComponent("AssetType")!

  return (_openBlock(), _createBlock(_component_AssetType, {
    readOnly: false,
    assetType: _ctx.assetType,
    key: _ctx.assetType.id,
    onOnSubmit: _ctx.SaveEdit
  }, null, 8, ["assetType", "onOnSubmit"]))
}