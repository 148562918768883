<template>
    <div>
    <form @submit.prevent="$emit('on-submit', form)">
      <div class="mb-3">
        <label for="name" class="form-label">Asset Type Name</label>
        <input class="form-control" type="text" name="name" v-model="form.name" :disabled="readOnly == 1" />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Description</label>
        <input class="form-control" type="text" name="description" v-model="form.description" :disabled="readOnly == true" />
      </div>
      <div class="mb-3">
        <label for="note" class="form-label">Test Interval</label>
        <input class="form-control" type="text" name="note" v-model="form.testInterval" :disabled="readOnly == true"  />
      </div>
      <button class="btn btn-default" type="submit" v-if="readOnly != true">Submit</button>
    </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      assetType: {
        type: Object,
      },
      readOnly: {type: Boolean}
    },
    methods: {
        async getData() { 
        },
    },
  
    created() {
        console.log(this.id)
        this.getData();
    },
    data() {
      return {
        form: { 
          name: this.assetType?.name || "",
          description: this.assetType?.description || "",
          testInterval: this.assetType?.testInterval || 0
        }
      };
    },
  };
  </script>