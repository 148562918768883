import { Asset } from "./Asset";

export class JobAsset {
    constructor(asset: Asset) {
        this.asset = asset;
        this.id = -1;
        this.errors = [];
        this.isDeleted = false;
        this.doubleBooked = false;
        this.expired = false;
    }
    id: number;
    asset: Asset;
    errors: string[] 
    isDeleted: boolean;
    doubleBooked: boolean;
    expired: boolean;
}