import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetTestView = _resolveComponent("AssetTestView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.errors.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("b", null, "Please correct the following error(s):", -1)),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
                return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AssetTestView, {
        onOnSubmit: _ctx.CreateAssetTest,
        internalAssetId: _ctx.assetId
      }, null, 8, ["onOnSubmit", "internalAssetId"])
    ])
  ]))
}