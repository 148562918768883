<template>
    <h1>{{ asset.name }}</h1>
    <AssetView :readOnly="true" :asset="asset" :key="asset.id"> </AssetView>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <router-link :to="{ name: 'AssetEdit', params: { id: id } }" v-slot="{ navigate }"
                             style="text-decoration: none; color: inherit;" class="button">
                    <div @click="navigate"><span class="material-symbols-outlined">edit</span><br />Edit</div>
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{ name: 'AssetTestCreate', params: { assetId: id } }" v-slot="{ navigate }"
                             style="text-decoration: none; color: inherit;" class="button">
                    <div @click="navigate"><span class="material-symbols-outlined">science</span><br />Test</div>
                </router-link>
            </div>
            <div class="col-sm">
                <div @click="quickPass" style="cursor: pointer"><span class="material-symbols-outlined">Done</span><br />Quick Pass</div>
            </div>
            <div class="col-sm">
                <div @click="print" style="cursor: pointer"><span class="material-symbols-outlined">print</span><br />Print</div>
            </div>
        </div>
    </div>
    <div class="container">
        <table class="table">
            <thead>
                <tr>
                    <td>Test Date</td>
                    <td>Status</td>
                    <td>Note</td>
                    <td>Next Test</td>
                    <td>User</td>
                    <td>Details</td>
                </tr>
            </thead>
            <tr v-for="test in asset.assetTests" :key="test.id">
                <td>{{ format_date(test.testTime) }}</td>
                <td>
                    <span class="material-symbols-outlined" v-if="test.testResult == 0">done</span>
                    <span class="material-symbols-outlined" v-if="test.testResult == 1">close</span>
                </td>
                <td>{{ test.note }}</td>
                <td>{{ format_date(test.nextTestDue) }}</td>
                <td>{{ test.user.userName }}</td>
                <td><router-link :key="test.id"
                        :to="{ name: 'AssetTestDetails', params: { assetTestId: test.id, assetId: test.assetId } }"
                        style="text-decoration: none; color: inherit;"> <span
                            class="material-symbols-outlined">info</span></router-link> </td>

            </tr>
        </table>
    </div>
</template>


<script lang="ts">

    import { defineComponent } from 'vue';
    import AssetView from "./Asset.vue";
    import moment from 'moment'

    import { printZpl } from '../../printing';
    import { Asset } from '@/types/Asset';
    import { AssetTest } from '@/types/AssetTest';
    import { User } from '@/types/User';

export default defineComponent({
    name: 'AssetDetails',
    components: { AssetView },
    props: {
        id: String,
        printLabel: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        $route() {
            this.getData();
        }
    },
    data() {
        return {
            asset: {} as Asset,
            AssetTests: [] as AssetTest[],
            latestAssetTestId: -1,
            companyId: this.$cookies.get('companyId'),
        };
    },

    methods: {
        async getData() {
            try {

                let companyId = this.$cookies.get('companyId');
                console.log("Getting data")
                const response = await this.axios.get("company/" + companyId + "/asset/" + this.id);
                // JSON responses are automatically parsed.
                this.asset = response.data as Asset;
                this.latestAssetTestId = 1;
                this.latestAssetTestId = Math.max(...this.asset.assetTests.map(o => o.id))
                console.log(this.asset)
                console.log("printLabel = " + this.$route.query.printLabel)
                if (this.$route.query.printLabel) {
                    this.print()
                }
            } catch (error) {
                console.log(error);
            }
        },
        format_date(value: string) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        async print() {

                let url: string = `/company/${this.companyId}/assettest/${this.latestAssetTestId}/print`

                let zplResp = await this.axios.get(url);
                let zpl: string = zplResp.data;
                await this.axios.post(`/company/${this.companyId}/audit`, { action: "Printed asset test", body: zpl, entity: "AssetDetails" });
                printZpl(zpl)
            
        },
        handleKeyEvent(e: KeyboardEvent) {

            if (e.altKey && e.key == "t") {
                console.log("Test");
                this.$router.push({ name: "AssetTestCreate", params: { assetId: this.id }})
            }


        },


        async quickPass() {

            await this.axios.post(`company/${this.companyId}/assettest`, {
                assetId: this.id,
                note: "Quick Pass",
                testResult: 0,
            }).then(async (response) => {
                this.latestAssetTestId = response.data.id;
                await this.print();
                await this.getData();
            }
            )
        }
    },

    mounted() {
        console.log("mounted")
    document.addEventListener('keydown', this.handleKeyEvent.bind(this));
    },

    created() {
        console.log(this.id)
        this.getData();
},

    beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyEvent);

}
});

</script>


