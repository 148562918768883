import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobView = _resolveComponent("JobView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Job Create", -1)),
    _createVNode(_component_JobView, {
      editable: true,
      onOnSubmit: _ctx.CreateJob
    }, null, 8, ["onOnSubmit"])
  ], 64))
}