<template>
    <div class="container">
        <form @submit.prevent="$emit('on-submit', form)">
            <div class="form-group mb-3">
                <label for="companyName">Company Name</label>
                <input type="text" class="form-control" id="companyName" v-model="form.name" placeholder="Enter company name" :disabled="readOnly == true">
            </div>
            <div class="form-group mb-3">
                <label for="companyDescription">Phone Number</label>
                <input type="text" class="form-control" id="companyDescription" v-model="form.phone" placeholder="Enter company description" :disabled="readOnly == true">
            </div>
            <div v-for="email in form?.emails" :key="email.email">
                <div class="form-group mb-3">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="adminEmail" v-model="email.email" placeholder="Enter admin email" :disabled="readOnly == true" :key="email.email">
                </div>
                <div class="form-group mb-3">
                    <input type="checkbox" class="" id="ownercheckbox" v-model="email.isOwner" placeholder="" :disabled="readOnly == true" :key="email.email">   
                    <label for="ownercheckbox">Owner</label> 
                </div>
                <div class="form-group mb-3">
                    <input type="checkbox" class="" id="admincheckbox" v-model="email.isAdmin" placeholder="" :disabled="readOnly == true" :key="email.email">   
                    <label for="admincheckbox">Admin</label> 
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm">
                    <button type="button" class="btn btn-primary" @click="addEmail" :disabled="readOnly == true">Add Email</button>                  
            <router-link v-if="edit==true" :to="{ name: 'CompanyEdit', params: { id: company.id } }" v-slot="{ navigate }"
                             style="text-decoration: none; color: inherit;" class="button">
                    <div @click="navigate"><span class="material-symbols-outlined">edit</span><br />Edit</div>
                </router-link>

                             </div>
            <div class="col-sm">
            <button type="submit" class="btn btn-primary" :disabled="readOnly == true">Submit</button>            </div>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'CompanyView',
    methods: {
        getData() {
        },
        addEmail(e: any) {
            console.log("Adding email")
            this.form?.emails.push({email: "", isAdmin: false, isOwner: false});
            console.log(this.form?.emails),
            e.preventDefault()
        }
    },
    props: {
        company: {
            type: Object,
        },
        readOnly: {type: Boolean},
        edit: {type: Boolean}
    },
    
    data() {
        return {
        form: {
            name: this.company?.name || "",
            emails: this.company?.emails || [],
        }
        }
    },
})




</script>