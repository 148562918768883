import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 0,
  class: "material-symbols-outlined",
  style: {"color":"orange"}
}
const _hoisted_15 = {
  key: 1,
  class: "material-symbols-outlined",
  style: {"color":"red"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Column = _resolveComponent("Column")!
  const _component_PVButton = _resolveComponent("PVButton")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.$emit('on-submit', _ctx.form)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[8] || (_cache[8] = _createElementVNode("label", {
          for: "name",
          class: "form-label"
        }, "Name:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "name",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          disabled: !_ctx.editable,
          required: ""
        }, null, 8, _hoisted_3), [
          [_vModelText, _ctx.form.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[9] || (_cache[9] = _createElementVNode("label", {
          for: "client",
          class: "form-label"
        }, "Client:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "client",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.client) = $event)),
          disabled: !_ctx.editable,
          required: ""
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.form.client]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("label", {
          for: "description",
          class: "form-label"
        }, "Description:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "client",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.description) = $event)),
          disabled: !_ctx.editable
        }, null, 8, _hoisted_7), [
          [_vModelText, _ctx.form.description]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { for: "note" }, "Note:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "client",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.note) = $event)),
          disabled: !_ctx.editable
        }, null, 8, _hoisted_9), [
          [_vModelText, _ctx.form.note]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[12] || (_cache[12] = _createElementVNode("label", {
          for: "startDate",
          class: "form-label"
        }, "Start Date:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "form-control",
          id: "startDate",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.startDate) = $event)),
          disabled: !_ctx.editable
        }, null, 8, _hoisted_11), [
          [_vModelText, _ctx.form.startDate]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[13] || (_cache[13] = _createElementVNode("label", {
          for: "endDate",
          class: "form-label"
        }, "End Date:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "form-control",
          id: "endDate",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.endDate) = $event)),
          disabled: !_ctx.editable
        }, null, 8, _hoisted_13), [
          [_vModelText, _ctx.form.endDate]
        ])
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Assets:", -1)),
      _createVNode(_component_AutoComplete, {
        modelValue: _ctx.selectedAsset,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedAsset) = $event)),
        suggestions: _ctx.filteredAssets,
        onOptionSelect: _ctx.selected,
        "complete-method": "searchAssets",
        onComplete: _ctx.searchAssets,
        placeholder: "Search Assets",
        disabled: !_ctx.editable
      }, {
        option: _withCtx((slotProps) => [
          _createElementVNode("div", null, "Name:" + _toDisplayString(slotProps.option.name), 1),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", null, "Barcode:" + _toDisplayString(slotProps.option.assetBarcode), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "suggestions", "onOptionSelect", "onComplete", "disabled"]),
      _createVNode(_component_DataTable, {
        value: _ctx.form.jobAssets,
        paginator: true,
        rows: 5,
        rowsPerPageOptions: [5,10,20]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "asset.name",
            header: "Name"
          }),
          _createVNode(_component_Column, {
            field: "asset.assetBarcode",
            header: "Barcode"
          }),
          _createVNode(_component_Column, {
            header: "Warnings",
            style: {"width":"10%"}
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.expired == true)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, "warning"))
                : _createCommentVNode("", true),
              (slotProps.data.doubleBooked == true)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, "error"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { style: {"width":"10%"} }, {
            body: _withCtx(({ data, row, index }) => [
              (_ctx.editable==true)
                ? (_openBlock(), _createBlock(_component_PVButton, {
                    key: 0,
                    type: "button",
                    onClick: ($event: any) => (_ctx.DeleteRow(data, index, row)),
                    text: "",
                    rounded: ""
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("span", {
                        style: {"color":"black"},
                        class: "material-symbols-outlined"
                      }, "delete", -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_PVButton, {
        class: "btn btn-default",
        type: "submit",
        hidden: !_ctx.editable
      }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [
          _createTextVNode("Save")
        ])),
        _: 1
      }, 8, ["hidden"])
    ], 32)
  ]))
}