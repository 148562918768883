

<template>
    <h1>Job Create</h1>
    <JobView :editable="true" @on-submit="CreateJob"></JobView>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import JobView from './JobView.vue';
import { Job } from '@/types/Job';

export default defineComponent({
    components: { JobView },
    name: 'JobCreate',
    data() {
        return {
            job: Job
        };
    },
    methods: {
        CreateJob(job: Job){
            console.log(job.name);
            
            let companyId = this.$cookies.get('companyId');
            this.axios.post(`company/${companyId}/job`, job).then(  (response) => {
                const self = this;
                self.$router.push({ name: 'JobDetails', params: { id: response.data.id }})
            }
            )
        }
    },
    created() {
        console.log("Created")
    }
})
</script>