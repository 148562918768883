<template>
    <JobView :editable="false" :job="job" :key="job.id"></JobView>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <router-link :to="{ name: 'JobEdit', params: { id: id } }" v-slot="{ navigate }"
                             style="text-decoration: none; color: inherit;" class="button">
                    <div @click="navigate"><span class="material-symbols-outlined">edit</span><br />Edit</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import JobView from './JobView.vue';
import { Job } from '@/types/Job';
export default defineComponent({
    components: { JobView },
    name: 'JobDetails',
    data() {
        return {
            job: {} as Job
        };
    },
    props: {
        id: String
    },
    created() {
        console.log("Created")
        this.getData();
    },
    methods: {
        async getData() {
            let companyId = this.$cookies.get('companyId');
            var res = await this.axios.get(`company/${companyId}/job/${this.$route.params.id}`)

            this.job = res.data as Job;
            console.log(this.job)
            console.log(this.job.name)
        }
    }
})

</script>