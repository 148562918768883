<template>
    <div class="container">
        <form @submit.prevent="$emit('on-submit', form)">
            <div class="mb-3">
                <label for="name" class="form-label" >Name:</label>
                <input type="text" class="form-control" id="name" v-model="form.name" :disabled="!editable" required/>
            </div>
            <div class="mb-3">
                <label for="client" class="form-label">Client:</label>
                <input type="text" class="form-control" id="client" v-model="form.client" :disabled="!editable" required />
            </div>
            <div class="mb-3">
                <label for="description" class="form-label">Description:</label>
                <input type="text" class="form-control" id="client" v-model="form.description" :disabled="!editable" />
            </div>
            <div class="mb-3">
                <label for="note">Note:</label>
                <input type="text" class="form-control" id="client" v-model="form.note" :disabled="!editable" />
            </div>
            <div class="mb-3">

                <label for="startDate" class="form-label">Start Date:</label>
                <input type="date" class="form-control" id="startDate" v-model="form.startDate" :disabled="!editable" />
            </div>
            <div class="mb-3">

                <label for="endDate" class="form-label">End Date:</label>
                <input type="date" class="form-control" id="endDate" v-model="form.endDate" :disabled="!editable" />
            </div>
            <h2>Assets:</h2>
            <AutoComplete v-model="selectedAsset" :suggestions="filteredAssets" v-on:option-select="selected" complete-method="searchAssets" @complete="searchAssets" placeholder="Search Assets" :disabled="!editable" >
                <template #option="slotProps">
                    <div>Name:{{slotProps.option.name}}</div>
                    <br />
                    <div>Barcode:{{slotProps.option.assetBarcode}}</div>
                </template>
            </AutoComplete>
            <DataTable :value="form.jobAssets" :paginator="true" :rows="5" :rowsPerPageOptions="[5,10,20]" >
                <Column field="asset.name" header="Name"></Column>
                <Column field="asset.assetBarcode" header="Barcode"></Column>
                <Column header="Warnings" style="width: 10%;">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.expired == true" class="material-symbols-outlined" style="color: orange;">warning</span>
                        <span v-if="slotProps.data.doubleBooked == true" class="material-symbols-outlined" style="color: red;">error</span>
                    </template>
                </Column>
            <Column style="width: 10%;">
                <template #body="{ data, row, index }">
                    <PVButton  type="button"  @click="DeleteRow(data, index, row)" text rounded v-if="editable==true" >
                        <span style="color: black;" class="material-symbols-outlined">delete</span> </PVButton>
                </template>
            </Column>
            </DataTable>
            <PVButton class="btn btn-default" type="submit" :hidden="!editable">Save</PVButton>
        </form>
    </div>
</template>

<script lang="ts">
import { Job } from '@/types/Job';
import { defineComponent } from 'vue';
import PVButton from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { JobAsset } from '@/types/JobAsset';
import { Asset } from '@/types/Asset';
import { AssetSummary } from '@/types/AssetSummary';

export default defineComponent({
    name: 'JobView',
    props: {
        editable: Boolean,
        job: Job
    },
    data() {
        return {
            form: {
                name: this.job?.name || '',
                client: this.job?.client || '',
                startDate: this.job?.startDate || new Date().toISOString().slice(0, 10),
                endDate: this.job?.endDate || new Date().toISOString().slice(0, 10),
                description: this.job?.description || '',
                note: this.job?.note || '',
                jobAssets: this.job?.jobAssets || []

            } as Job,
            selectedAsset: null,
            filteredAssets: [],
            deletedAssets: [] as AssetSummary[]
        };
    },
    components: {
        PVButton,
        AutoComplete,
        DataTable,
        Column
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let companyId = this.$cookies.get('companyId');
                const response = await this.axios.get(`company/${companyId}/job`);
                //this.job = response.data as Job;
            } catch (error) {
                console.error('Error fetching job:', error);
            }
        },
        async searchAssets(event: any) {
            console.log('searchAssets', event);
            try {
                let companyId = this.$cookies.get('companyId');
                const response = await this.axios.get(`company/${companyId}/search?name=${event.query}`);
                this.filteredAssets = response.data;

            } catch (error) {
                console.error('Error fetching assets:', error);
            }
        },
        selected(event: any) {
            console.log('selected', event.value);
            let jobAsset: JobAsset = new JobAsset(event.value)
            this.form.jobAssets.push(jobAsset);
            this.selectedAsset = null;
        },
        DeleteRow(data: AssetSummary, index: number, row: any) {
            console.log(index);
            console.log(data);
            const assetIndex = this.form.jobAssets.findIndex(asset => asset.id === data.id);
            if (assetIndex !== -1) {
                this.form.jobAssets.splice(assetIndex, 1);
                this.deletedAssets.push(data);
            }
        }
    }
})
</script>

<style scoped>
/* Add your custom styles here */
</style>