import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  class: "col-sm",
  style: {"cursor":"pointer"}
}
const _hoisted_9 = {
  class: "col-sm",
  style: {"cursor":"pointer"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      id: "testTagForm",
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$emit('on-submit', _ctx.form)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[6] || (_cache[6] = _createElementVNode("label", {
          for: "name",
          class: "form-label"
        }, "Asset Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          name: "name",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assetName) = $event)),
          disabled: true == true
        }, null, 512), [
          [_vModelText, _ctx.assetName]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[7] || (_cache[7] = _createElementVNode("label", {
          for: "note",
          class: "form-label"
        }, "Note", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          name: "note",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.note) = $event)),
          disabled: _ctx.readOnly == true
        }, null, 8, _hoisted_3), [
          [_vModelText, _ctx.form.note]
        ])
      ]),
      (_ctx.readOnly === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[8] || (_cache[8] = _createElementVNode("label", {
              for: "testResult",
              class: "form-label"
            }, "Test Result", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "aria-label": "Default select example",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.testResult) = $event)),
              disabled: _ctx.readOnly == true
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testResults, (option) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: option.id,
                  key: option.id
                }, _toDisplayString(option.name), 9, _hoisted_6))
              }), 128))
            ], 8, _hoisted_5), [
              [_vModelSelect, _ctx.form.testResult]
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.readOnly != true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.complete(1)))
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "Close", -1),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Fail")
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.readOnly != true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.complete(0)))
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "Done", -1),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Pass")
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ], 32)
  ]))
}