<template>
    <div class="container">
        CompanySettings

        <table class="table">
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Description</td>
                    <td>Test interval</td>
                    <td></td>
                </tr>
            </thead>
            <!--router-link v-for="asset in assets" :key="asset.id"
                     :to="{ name: 'Asset', params: { id: asset.id } }"
                     >
            <tr-->
            <tr v-for="asset in assetTypes" :key="asset.id">

                <td>{{ asset.name }}</td>
                <td>{{ asset.description }}</td>
                <td>{{ asset.testInterval }}</td>
                <td><router-link :key="asset.id" :to="{ name: 'AssetTypeDetails', params: { id: asset.id } }"> <span class="material-symbols-outlined">info</span></router-link> </td>
            </tr>
        </table>
        <div id="nav">
            <router-link :to="{ name: 'AssetTypeCreate'}">Create Asset Type</router-link>
        </div>

        <div class="container">
            About
            ServerBuildTime: {{ about.buildTime }}
            ClientBuildTime: 2024-10-02T19:47:27+00:00
        </div>
    </div>
</template>>


<script lang="ts">
    import { AssetType } from '@/types/AssetType';
import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'CompanySettings',
        props: {
        },
        data() {
            return {
                assetTypes: [] as AssetType[],
                about: {} as { buildTime: string}
            };
        },

        methods: {
            async getData() {
                try {
                    console.log("Getting data")
                    
                    let companyId = this.$cookies.get('companyId');
                    const response = await this.axios.get(
                        `company/${companyId}/assetType`
                    );
                    // JSON responses are automatically parsed.
                    this.assetTypes = response.data;

                    const aboutResponse = await this.axios.get("about");
                    console.log(aboutResponse.data)
                    this.about = aboutResponse.data
                } catch (error) {
                    console.log(error);
                }
            },
        },

        created() {
            console.log("Created")

            this.getData();
        }, 
    });

</script>


