<template>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                
            <router-link :to="{ name: 'JobCreate' }" style="text-decoration: none; color: inherit;">
                <div><span class="material-symbols-outlined">Add_Circle</span><br />Create</div>
            </router-link>
            </div>
        </div>
        <div class="container">
            <DataTable :value="jobs" :paginator="true" :rows="10" :rowsPerPageOptions="[5, 10, 20]">
                <Column field="name" header="Job Name"></Column>
                <Column field="client" header="Client" sortable></Column>
                <Column>
                    <template #body="slotProps">
                        <router-link :to="{ name: 'JobDetails', params: { id: slotProps.data.id } }"
                            style="text-decoration: none; color: inherit;">
                            <span class="material-symbols-outlined">info</span>
                        </router-link>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script lang="ts">
import { Job } from '@/types/Job';
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default defineComponent({
    data() {
        return {
            jobs: [] as Job[]
        };
    },
    created() {
        this.fetchJobs();
    },
    components: {
        DataTable,
        Column
    },
    methods: {
        async fetchJobs() {
            try {

                let companyId = this.$cookies.get('companyId');
                const response = await this.axios.get(
                    `company/${companyId}/job`
                );
                // JSON responses are automatically parsed.
                this.jobs = response.data as Job[];
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        }
    }
});
</script>

<style>
/* Add your custom styles here */
</style>