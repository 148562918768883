
<template>
    <AssetType @on-submit="CreateAssetType"> </AssetType>
</template>

<script lang="ts">
    
    import { defineComponent } from 'vue';
    import AssetType from './AssetType.vue';
        export default defineComponent({
            components: { AssetType },
            name: 'CreateAssetType',
            methods: {
                CreateAssetType(assetTypeDetails: any){
                    console.log(assetTypeDetails.name);
                    
                    let companyId = this.$cookies.get('companyId');
                    this.axios.post(`company/${companyId}/assettype`, {
                        name: assetTypeDetails.name,
                        description: assetTypeDetails.description,
                        testInterval: assetTypeDetails.testInterval
                    }).then(  (response) => {
                        const self = this;
                        self.$router.push({ name: 'AssetTypeDetails', params: { id: response.data.id } })
                    }
                    )
                }
            }
        })
</script>  