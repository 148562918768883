<template>
    <AssetList :assets="assets"></AssetList>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import AssetList from './AssetList/AssetList.vue'
    import { AssetSummary } from '@/types/AssetSummary';
    export default defineComponent( {
        props: {
            readOnly: { type: Boolean }
        },
        watch: {
            $route() {
                this.getData();
            }
        },
        data() {
            return {
                assets: [] as AssetSummary[],
                searchString: "" as any

            };
        },
        name: "SearchResults",
        methods: {
            async getData() {
                this.searchString = this.$route.query.searchString;
                console.log(this.searchString)
                let companyId = this.$cookies.get('companyId');
                var res = await this.axios.get(`company/${companyId}/search/${this.searchString}`)
                this.assets = res.data as AssetSummary[];
            }
        },
        components: { AssetList },
        created() {
            console.log("Created")
            this.getData();
        }
    });
</script>