import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { id: "nav" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createTextVNode(" CompanySettings ")),
    _createElementVNode("table", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, "Name"),
          _createElementVNode("td", null, "Description"),
          _createElementVNode("td", null, "Test interval"),
          _createElementVNode("td")
        ])
      ], -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetTypes, (asset) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: asset.id
        }, [
          _createElementVNode("td", null, _toDisplayString(asset.name), 1),
          _createElementVNode("td", null, _toDisplayString(asset.description), 1),
          _createElementVNode("td", null, _toDisplayString(asset.testInterval), 1),
          _createElementVNode("td", null, [
            (_openBlock(), _createBlock(_component_router_link, {
              key: asset.id,
              to: { name: 'AssetTypeDetails', params: { id: asset.id } }
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
              ])),
              _: 2
            }, 1032, ["to"]))
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: { name: 'AssetTypeCreate'} }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Create Asset Type")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, " About ServerBuildTime: " + _toDisplayString(_ctx.about.buildTime) + " ClientBuildTime: 2024-10-02T19:47:27+00:00 ", 1)
  ]))
}