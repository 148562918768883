import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetView = _resolveComponent("AssetView")!

  return (_openBlock(), _createBlock(_component_AssetView, {
    onOnSubmit: _ctx.EditAsset,
    asset: _ctx.asset,
    key: _ctx.asset.id
  }, null, 8, ["onOnSubmit", "asset"]))
}