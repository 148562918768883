

<template>
    <JobView :editable="true" :job="job" @on-submit="EditJob" :key="job.id"></JobView>
</template>

<script lang="ts">
import JobView from './JobView.vue';
import { Job } from '@/types/Job';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { JobView },
    name: 'JobEdit',
    data() {
        return {
            job: {} as Job
        };
    },
    props: {
        id: String
    },

    methods: {
        EditJob(job: Job){
            console.log(job.name);
            
            let companyId = this.$cookies.get('companyId');
            this.axios.put(`company/${companyId}/job/${this.$route.params.id}`, job).then(  (response) => {
                const self = this;
                self.$router.push({ name: 'JobDetails', params: { id: response.data.id }})
            }
            )
        },
        async GetData() {
            let companyId = this.$cookies.get('companyId');
            var res = await this.axios.get(`company/${companyId}/job/${this.$route.params.id}`)
            this.job = res.data as Job;
            console.log(this.job)
            console.log(this.job.name)
        }
    },
    created() {
        console.log("Created")
        this.GetData();
    }
})
</script>