<template>
    <AssetType  :readOnly="true" :assetType="assetType" :key="assetType.id" > </AssetType>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <router-link :to="{ name: 'AssetTypeEdit', params: { id: id } }" v-slot="{ navigate }"
                             style="text-decoration: none; color: inherit;" class="button">
                    <div @click="navigate"><span class="material-symbols-outlined">edit</span><br />Edit</div>
                </router-link>
            </div>
            <div class="col-sm"></div>
            <div class="col-sm">
                <button @click="deleteAssetType" class="button"><span class="material-symbols-outlined">delete</span><br />Delete</button>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

    import { defineComponent } from 'vue';
import AssetType from "./AssetType.vue";

    export default defineComponent({
        name: 'AssetTypeDetails',
        components: {  AssetType },
        props: {id: Number},
        data() {
            return {
                assetType: {
                    id: Number,
                }
,
            };
        },

        methods: {
            async getData() { 
                try {
                    console.log("Getting data")
                    
                    let companyId = this.$cookies.get('companyId');
                    const response = await this.axios.get(`company/${companyId}/assetType/${this.id}`);
                    // JSON responses are automatically parsed.
                    this.assetType = response.data;
                } catch (error) {
                    console.log(error);
                }
            },
            deleteAssetType() {
                // Show confirmation prompt before deletion
                if (!confirm("Are you sure you want to delete this asset type?")) {
                    return;
                }

                let companyId = this.$cookies.get('companyId');
                this.axios.delete(`company/${companyId}/assettype/${this.id}`)
                    .then(() => {
                        const self = this;
                        
                        self.$router.push({ name: 'CompanySettings' })
                    })
            }
        },

        created() {
            console.log(this.id)
            this.getData();
        },
    });

</script>


