import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "col-sm" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "col-sm" }
const _hoisted_8 = { class: "col-sm" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "table" }
const _hoisted_11 = {
  key: 0,
  class: "material-symbols-outlined"
}
const _hoisted_12 = {
  key: 1,
  class: "material-symbols-outlined"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetView = _resolveComponent("AssetView")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.asset.name), 1),
    (_openBlock(), _createBlock(_component_AssetView, {
      readOnly: true,
      asset: _ctx.asset,
      key: _ctx.asset.id
    }, null, 8, ["asset"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: { name: 'AssetEdit', params: { id: _ctx.id } },
            style: {"text-decoration":"none","color":"inherit"},
            class: "button"
          }, {
            default: _withCtx(({ navigate }) => [
              _createElementVNode("div", { onClick: navigate }, _cache[2] || (_cache[2] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "edit", -1),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Edit")
              ]), 8, _hoisted_4)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: { name: 'AssetTestCreate', params: { assetId: _ctx.id } },
            style: {"text-decoration":"none","color":"inherit"},
            class: "button"
          }, {
            default: _withCtx(({ navigate }) => [
              _createElementVNode("div", { onClick: navigate }, _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "science", -1),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Test")
              ]), 8, _hoisted_6)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.quickPass && _ctx.quickPass(...args))),
            style: {"cursor":"pointer"}
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "Done", -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode("Quick Pass")
          ]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.print && _ctx.print(...args))),
            style: {"cursor":"pointer"}
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, "print", -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode("Print")
          ]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("table", _hoisted_10, [
        _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, "Test Date"),
            _createElementVNode("td", null, "Status"),
            _createElementVNode("td", null, "Note"),
            _createElementVNode("td", null, "Next Test"),
            _createElementVNode("td", null, "User"),
            _createElementVNode("td", null, "Details")
          ])
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asset.assetTests, (test) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: test.id
          }, [
            _createElementVNode("td", null, _toDisplayString(_ctx.format_date(test.testTime)), 1),
            _createElementVNode("td", null, [
              (test.testResult == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, "done"))
                : _createCommentVNode("", true),
              (test.testResult == 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "close"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", null, _toDisplayString(test.note), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.format_date(test.nextTestDue)), 1),
            _createElementVNode("td", null, _toDisplayString(test.user.userName), 1),
            _createElementVNode("td", null, [
              (_openBlock(), _createBlock(_component_router_link, {
                key: test.id,
                to: { name: 'AssetTestDetails', params: { assetTestId: test.id, assetId: test.assetId } },
                style: {"text-decoration":"none","color":"inherit"}
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
                ])),
                _: 2
              }, 1032, ["to"]))
            ])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}