
<template>
    <AssetView @on-submit="EditAsset" :asset="asset" :key="asset.id" > </AssetView>
</template>

<script lang="ts">
    
    import AssetView from "./Asset.vue";
    import { defineComponent } from 'vue';
    import moment from 'moment'
        export default defineComponent({
            components: {AssetView},
            name: 'AssetEdit',
            props: { id: String },
            data() {
                return {
                    asset: {} as {
                        id: number,
                        companyId: number,
                        company: string,
                        assetTypeId: number,
                        assetType: string,


                    },
                    companyId: this.$cookies.get('companyId'),
                };
            },
            methods: {
                EditAsset(asset: any){
                    console.log(asset.name);                    
                    let companyId = this.$cookies.get('companyId');
                    this.axios.put(`company/${companyId}/asset/${this.id}`, {
                        name: asset.name,
                        note: asset.note,
                        description: asset.description,
                        assetTypeId: asset.assetTypeId
                    }).then(  (response) => {
                        const self = this;
                        self.$router.push({ name: 'AssetDetails', params: { id: response.data.id } })
                    }
                    )
                },
                async getData() {
                    try {

                        let companyId = this.$cookies.get('companyId');
                        console.log("Getting data")
                        const response = await this.axios.get("company/" + companyId + "/asset/" + this.id);
                        // JSON responses are automatically parsed.
                        this.asset = response.data;
                        console.log(this.asset)
                    } catch (error) {
                        console.log(error);
                    }
                },
                format_date(value: string) {
                    if (value) {
                        return moment(String(value)).format('DD/MM/YYYY')
                    }
                },
            },
                created() {
                    console.log("Editing asset " + this.id)
                    this.getData();
                },
        })
</script>  