<template>
    <div>
        <form id="testTagForm" @submit.prevent="$emit('on-submit', form)">
            <div class="mb-3">
                <label for="name" class="form-label">Asset Name</label>
                <input class="form-control" type="text" name="name" v-model="assetName" :disabled="true == true" />
            </div>
            <div class="mb-3">
                <label for="note" class="form-label">Note</label>
                <input class="form-control" type="text" name="note" v-model="form.note" :disabled="readOnly == true" />
            </div>
            <div v-if="readOnly === true" class="mb-3">
                <label for="testResult" class="form-label">Test Result</label>
                <select class="form-select" aria-label="Default select example" v-model="form.testResult" :disabled="readOnly == true">
                    <option v-for="option in testResults" :value="option.id" :key="option.id">
                        {{option.name}}
                    </option>
                </select>
            </div>
            <!--button class="btn btn-default" type="submit" v-if="readOnly != true">Submit</!--button-->

            <div class="row">
                <div class="col-sm" style="cursor: pointer;">
                    <div @click="complete(1)"  v-if="readOnly != true"><span class="material-symbols-outlined">Close</span><br />Fail</div>
                </div>
                <div class="col-sm" style="cursor: pointer;">
                    <div @click="complete(0)" v-if="readOnly != true"><span class="material-symbols-outlined">Done</span><br />Pass</div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from "vue"
    import { AssetTest } from "../../types/AssetTest";
    export default defineComponent({
        name: "AssetTestView",
        props: {
            assetTest: {
                type: Object as PropType<AssetTest>                
            },
            internalAssetId: String,
            readOnly: Boolean
        },
        data() {
            return {
                form: {
                    note: this.assetTest?.note || "",
                    testResult: this.assetTest ? this.assetTest.testResult : -1,
                    assetId: this.internalAssetId || -100
                },
                assetName: this.assetTest?.asset?.name || "" as String,
                testResults: [{"name": "Pass", "id": 0}, {"name": "Fail", "id": 1}]
            };
        },
        methods: {
            complete(result: number) {
                this.form.testResult = result;
                this.$emit('on-submit', this.form)
            },
            async getData() {
                try {
                    let companyId = this.$cookies.get('companyId');
                    console.log("Getting data")
                    const response = await this.axios.get("company/" + companyId + "/asset/" + this.internalAssetId);
                    // JSON responses are automatically parsed.
                    this.assetName = response.data.name;
                } catch (error) {
                    console.log(error);
                }
            },
            handleKeyEvent(e: KeyboardEvent) {
                if (e.altKey && e.key == "p") {
                    console.log("Pass");
                    this.complete(0)
                }
            }
        },

        created() {
            this.getData();
        },

        beforeUnmount() {
            document.removeEventListener('keydown', this.handleKeyEvent);

        },

        mounted() {
            console.log("mounted")
            document.addEventListener('keydown', this.handleKeyEvent.bind(this));
        }
    });
</script>