<template>
    <CompanyView :company="company" :readOnly="true" :edit="true" :key="company.id" @edit-company="edit"  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CompanyView from './CompanyView.vue'	
export default defineComponent({
    name: 'CompanyDetails',
    components: {
        CompanyView
    },
    methods: {
        async getData() {
            try {
                console.log("Getting data")
                const response = await this.axios.get("company/" + this.id);
                // JSON responses are automatically parsed.
                this.company = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async edit() {
            console.log("Editing")
            this.$router.push({ name: 'CompanyEdit', params: { id: this.id } })
        }
    },
    props: {
        id: Number,
    },
    data() {
        return {
            company: {
                id: Number,
            } 
        }
    },
    created() {
        console.log("Created")
        console.log(this.id)
        this.getData();
        console.log(this.company)
    },
})
</script>