import { createWebHistory, createRouter } from "vue-router";
import MainHome from "@/views/main-home.vue";
import MainAbout from "@/views/main-about.vue";
import OurAssets from "@/components/Assets.vue";
import AssetDetails from "@/components/Asset/AssetDetails.vue";
import AssetEdit from "@/components/Asset/AssetEdit.vue";
import AssetCreate from "@/components/Asset/AssetCreate.vue";
import CompanySettings from "@/components/CompanySettings.vue";
import AssetTypeCreate from "@/components/AssetType/AssetTypeCreate.vue";
import AssetTypeDetails from "@/components/AssetType/AssetTypeDetails.vue";
import AssetTypeEdit from "@/components/AssetType/AssetTypeEdit.vue";
import AssetTestCreate from "@/components/AssetTest/AssetTestCreate.vue";
import AssetTestDetails from "@/components/AssetTest/AssetTestDetails.vue"
import SearchResults from "@/components/SearchResults.vue"
import GlobalSettings from "@/components/GlobalSettings/GlobalSettings.vue"
import CompanyCreate from "@/components/Company/CompanyCreate.vue"
import CompanyDetails from "@/components/Company/CompanyDetails.vue"
import CompanyEdit from "@/components/Company/CompanyEdit.vue";
import AdhocLabel from "@/components/AdhocLabel/AdhocLabel.vue";
import JobList from "@/components/JobList/JobList.vue";
import JobCreate from "@/components/Job/JobCreate.vue";
import JobDetails from "@/components/Job/JobDetails.vue";
import JobEdit from "@/components/Job/JobEdit.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: MainHome,
    },
    {
        path: "/about",
        name: "MainAbout",
        component: MainAbout,
    },
    {
        path: "/asset/create",
        name: "AssetCreate",
        component: AssetCreate,
    },
    {
        path: "/asset/edit/:id",
        name: "AssetEdit",
        component: AssetEdit,
        props: true
    },
    {
        path: "/assets",
        name: "Assets",
        component: OurAssets,
    }, 
    {
        path: "/asset/:id",
        name: "AssetDetails",
        query: { printLabel: Boolean } ,
        component: AssetDetails,
        props: true
    },
    {
        path: "/companysettings",
        name: "CompanySettings",
        component: CompanySettings,
    },
    {
        path: "/assettype/create",
        name: "AssetTypeCreate",
        component: AssetTypeCreate,
    },
    {
        path: "/assettype/details/:id",
        name: "AssetTypeDetails",
        component: AssetTypeDetails,
        props: true
    },
    {
        path: "/assettype/edit/:id",
        name: "AssetTypeEdit",
        component: AssetTypeEdit,
        props: true
    },
    {
        path: "/asset/:assetId/test",
        name: "AssetTestCreate",
        component: AssetTestCreate,
        props: true
    },
    {
        path: "/asset/:assetId/test/details/:assetTestId",
        name: "AssetTestDetails",
        component: AssetTestDetails,
        props: true
    },
    {
        path: "/search",
        name: "SearchResults",
        query: { searchString: String } ,
        component: SearchResults,
        props: true,
    },
    {
        path: "/globalSettings",
        name: "GlobalSettings",
        component: GlobalSettings,
    },
    {
        path: "/company/create",
        name: "CompanyCreate",
        component: CompanyCreate,
    },
     {
        path: "/company/:id",
        name: "CompanyDetails",
        component: CompanyDetails,
        props: true

     },
     {
        path: "/company/edit/:id",
        name: "CompanyEdit",
        component: CompanyEdit,
        props: true
     },
     {
        path: "/adhocLabel",
        name: "AdhocLabel",
        component: AdhocLabel
     },
     {
        path: "/joblist",
        name: "JobList",
        component: JobList
     },
     {
        path: "/job/create",
        name: "JobCreate",
        component: JobCreate
     },
     {
         path: "/job/:id",
         name: "JobDetails",
         component: JobDetails,
         props: true
     },
     {
         path: "/job/edit/:id",
         name: "JobEdit",
         component: JobEdit,
         props: true
     }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;